import {Container} from '@mui/material';
import styled from 'styled-components';

export const InstallPowertoolsForAWSContainer = styled(Container)`
  margin-top: 120px;
  margin-bottom: 230px;
  min-height: 100vh;

  ${({theme}) => theme.breakpoints.down('md')} {
    margin-top: 90px;
    margin-bottom: 100px;
  }
`;
