import {Box} from '@mui/material';
import styled from 'styled-components';
import {THEME} from '@constants';

const tabRadius = 8;

export const InstallLanguageWrapper = styled(Box)`
  .title-text {
    width: 50%;
    font-weight: 800;
    margin-left: 25%;
    margin-right: 25%;

    ${({theme}) => theme.breakpoints.down('md')} {
      width: 100%;
      margin: 0;
    }
    span {
      font-family: var(--PTMono);
      color: ${({theme}) => theme.palette.primary.main};
      font-weight: 400;
    }
  }

  .language-images {
    display: flex;
    justify-content: center;
  }

  .tabs-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    width: 100%;

    .tabs {
      display: flex;
      overflow-x: auto;
      align-self: center;
      justify-self: center;
      padding: 4px;
      max-width: 100%;

      ${({theme}) => theme.breakpoints.down('sm')} {
        width: 100%;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .language-icon {
        transition: all 0.7s;
        align-self: center;
      }

      .tab-container {
        ${({theme}) => theme.breakpoints.down('sm')} {
          flex: 1;
        }

        .tab {
          flex: 1;
          padding: 4px;
          cursor: pointer;

          background-color: ${({theme}) =>
            theme.palette.mode === THEME.dark
              ? theme.palette.background.paper
              : theme.palette.info.main};

          .tabWrapper {
            transition: background-color 0.7s;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 30px;
            border-radius: ${tabRadius}px;

            ${({theme}) => theme.breakpoints.down('md')} {
              padding: ${tabRadius}px 15px;
            }

            ${({theme}) => theme.breakpoints.down('sm')} {
              padding: ${tabRadius}px 8px;
            }

            &.active {
              transition: background-color 0.7s;
              background-color: ${({theme}) => theme.palette.common.white};
              color: ${({theme}) => theme.palette.common.black};
            }
          }
        }
      }

      & :first-child .tab {
        border-radius: ${`${tabRadius + 6}px 0 0 ${tabRadius + 6}px`};
      }
      & :last-child .tab {
        border-radius: ${`0 ${tabRadius + 6}px ${tabRadius + 6}px 0`};
      }
    }

    .content-container {
      background: ${({theme}) => theme.palette.background.paper};
      box-shadow: 0px 4px 10px 0px #0000000d;
      border-radius: 8px;

      ${({theme}) => theme.breakpoints.down('sm')} {
        background: ${({theme}) => theme.palette.background.default};
        box-shadow: none;
      }

      .command {
        background-color: ${({theme}) => theme.palette.info.light};
        padding: 12px 18px;
        border-radius: 4px;
        border-style: dashed;
        border-width: 1px;
        border-color: ${({theme}) => theme.palette.info.contrastText};

        .command-text-container {
          overflow: auto;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
`;
