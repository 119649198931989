import {useMemo} from 'react';
import {Box, Link as MuiLink, useMediaQuery, useTheme} from '@mui/material';
import {Link} from 'react-router-dom';
import communityWebDark from '@images/community-web-dark.png';
import communityWebLight from '@images/community-web-light.png';
import communityImage from '@images/community.png';
import conversationDark from '@images/conversation-dark.png';
import conversation from '@images/conversation.png';
import {ReactComponent as ArrowLeft} from '@svgs/arrow-left.svg';
import {ReactComponent as ArrowRight} from '@svgs/arrow-right.svg';
import Button from '@components/Button/Button';
import Typography from '@components/Typography/Typography';
import {THEME, appURLs} from '@constants';
import {ThrivingCommunityWrapper} from './styles';

const ThrivingCommunity = () => {
  const {palette, breakpoints} = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const isLightMode = palette.mode === THEME.light;

  const communityImg = useMemo(
    () => (isLightMode ? communityWebLight : communityWebDark),
    [isLightMode],
  );

  return (
    <ThrivingCommunityWrapper mt={isMobile ? 8 : 10}>
      <Box className={'header'}>
        {isMobile && <img src={communityImage} height={220} />}
        <Typography variant="h3" textAlign="center" fontWeight={800} mt={4}>
          Join our thriving community
        </Typography>
        <Typography
          mt={2}
          fontSize={18}
          fontWeight={400}
          color={palette.text.secondary}>
          Collaborate, innovate, and shape the future together!
        </Typography>
      </Box>
      <Box className={'join-wrapper'} mt={10}>
        {!isMobile && <ArrowRight stroke={palette.info.main} />}

        <MuiLink
          component={Link}
          to={appURLs.discordURL}
          target="_blank"
          width={'inherit'}
          textAlign={'center'}>
          <Button
            size="large"
            color="secondary"
            className={'join-community-btn'}>
            Join our Community
          </Button>
        </MuiLink>

        {!isMobile && <ArrowLeft stroke={palette.info.main} />}
      </Box>
      <img
        src={communityImg}
        alt="community image"
        className={'community-image'}
      />{' '}
      <img
        src={isLightMode ? conversation : conversationDark}
        width={'100%'}
        alt="conversation image"
        className={'conversation-image'}
      />
    </ThrivingCommunityWrapper>
  );
};

export default ThrivingCommunity;
