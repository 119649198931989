import {useMediaQuery, useTheme} from '@mui/material';
import InstallLanguage from '@components/InstallLanguage/InstallLanguage';
import GetGoing from './components/GetGoing/GetGoing';
import Install from './components/Install/Install';
// import JoinUsBar from './components/JoinUSBar/JoinUsBar';
import OpenSourceFreedom from './components/OpenSourceFreedom/OpenSourceFreedom';
import ThrivingCommunity from './components/ThrivingCommunity/ThrivingCommunity';
import TurbochargeYourCode from './components/TurbochargeYourCode/TurbochargeYourCode';
import UnleashPower from './components/UnleashPower/UnleashPower';
import VoicesOfSuccess from './components/VoicesOfSuccess/VoicesOfSuccess';
import {HomePageContainer} from './styles';

const HomePage = () => {
  const {breakpoints} = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <HomePageContainer>
      {/* <JoinUsBar /> */}
      <UnleashPower />
      <Install />
      <VoicesOfSuccess />
      <TurbochargeYourCode />
      {/* <HowPowertoolsWorks /> */}
      <ThrivingCommunity />
      {!isMobile && <InstallLanguage />}
      <OpenSourceFreedom />
      <GetGoing />
    </HomePageContainer>
  );
};

export default HomePage;
