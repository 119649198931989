import MenuIcon from '@mui/icons-material/Menu';
import {Box} from '@mui/material';
import styled from 'styled-components';

export const NavBarContainer = styled(Box)`
  display: flex;
  position: relative;
  .proj-name {
    font-size: 20px;
    text-decoration: none;
    font-weight: 800;
    color: ${({theme}) => theme.palette.text.primary};
  }

  .install-button {
    font-size: 16px;
    padding: 9px 20px;
  }
`;

export const NavItemsWrapper = styled(Box)`
  .navItems {
    font-family: var(--AmazonEmber);
    font-size: 16px;
    font-weight: 700;
    color: ${({theme}) => theme.palette.text.primary};
  }
  ${({theme}) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

export const StyledMenu = styled(MenuIcon)``;
