import {useState} from 'react';
import {Box, Link, useMediaQuery, useTheme} from '@mui/material';
import githubLogo from '@images/github-logo.png';
import Typography from '@components/Typography/Typography';
import {THEME, openSourceLinks} from '@constants';
import OpenSourceFreedomWrapper from './styles';

const OpenSourceFreedom = () => {
  const {palette, breakpoints} = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const [activeLogo, setActiveLogo] = useState<string | null>(null);
  const isDarkTheme = palette.mode === THEME.dark;

  const inActiveIconColor = isDarkTheme ? '#4F5965' : '#D6D6D6';
  const activeIconColor = isDarkTheme ? '#fff' : '#3178C6';

  return (
    <OpenSourceFreedomWrapper mt={20}>
      <Box ml={isMobile ? 3 : 0}>
        <img height={140} src={githubLogo} alt="github-logo" />
      </Box>

      <Typography variant="h3" className="title-text" mt={4}>
        Open source <br />
        freedom
      </Typography>

      <Typography
        mt={2}
        textAlign={'center'}
        color={'text.secondary'}
        fontSize={18}>
        Harness the Power of our MIT-Licensed Solution
      </Typography>

      <Box className="logos-container" mt={isMobile ? 4 : 8}>
        {openSourceLinks.map(
          ({
            id,
            title,
            Icon,
            DarkIcon,
            LightActiveIcon,
            DarkActiveIcon,
            githubLink,
          }) => {
            const isActive = !isMobile && activeLogo === id;

            let IconComp = Icon;

            if (isActive) {
              IconComp = isDarkTheme ? DarkActiveIcon : LightActiveIcon;
            } else if (isDarkTheme && DarkIcon) {
              //  ^ if there's a separate inactive icon for dark mode
              IconComp = DarkIcon;
            }

            return (
              <Box
                key={title}
                className={`logo-box`}
                onMouseEnter={() => setActiveLogo(id)}
                onMouseLeave={() => setActiveLogo(null)}>
                <IconComp
                  className={`language-icon ${isActive ? 'fade-in' : ''}`}
                  fill={isActive ? activeIconColor : inActiveIconColor}
                />
                <Typography mt={2} bold>
                  {title}
                </Typography>
                {!isMobile && (
                  <Link href={githubLink} target="_blank" underline="none">
                    <Typography
                      className="github-button"
                      textAlign={'center'}
                      mt={0.5}
                      color={'primary'}
                      bold
                      variant="subtitle2">
                      {'View on GitHub'}
                    </Typography>
                  </Link>
                )}
              </Box>
            );
          },
        )}
      </Box>
    </OpenSourceFreedomWrapper>
  );
};

export default OpenSourceFreedom;
