import {Box, useMediaQuery, useTheme} from '@mui/material';
import Marquee from 'react-fast-marquee';
import openSource from '@images/open-source-text.png';
import stripes from '@images/stripes.png';
import user from '@images/user.png';
import Typography from '@components/Typography/Typography';
import {
  LogosWrapper,
  OpenSourceWrapper,
  StyledCPQILogo,
  StyledCapitalOneLogo,
  StyledCaylentLogo,
  StyledCyberarkLogo,
  StyledHashnodeLogo,
  StyledTrek10Logo,
  UnleashPowerWrapper,
} from './styles';

const logos = [
  StyledCyberarkLogo,
  StyledCapitalOneLogo,
  StyledHashnodeLogo,
  StyledTrek10Logo,
  StyledCPQILogo,
  StyledCaylentLogo,
];

const UnleashPower = () => {
  const {breakpoints} = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const logosComp = (
    <LogosWrapper pt={isMobile ? 2 : 5}>
      {logos.map((Logo, index) => (
        <Logo key={index} />
      ))}
    </LogosWrapper>
  );

  return (
    <UnleashPowerWrapper>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        paddingY={6}>
        <Typography color={'text.primary'} variant="h3" bold>
          Unleash the {isMobile && <br />} power of
          <Typography color={'primary'} variant={'h3'} className={'serverless'}>
            {'<serverless>'}
          </Typography>{' '}
          with{' '}
          <span>
            Powertools <img src={stripes} alt="stripes" className={'stripes'} />
          </span>{' '}
          for AWS
        </Typography>
        <OpenSourceWrapper sx={{display: {xs: 'none', sm: 'block'}}}>
          <img src={openSource} alt={'open source image'} />
          <img src={user} alt={'open source image'} />
        </OpenSourceWrapper>
      </Box>
      {isMobile ? <Marquee>{logosComp}</Marquee> : logosComp}
    </UnleashPowerWrapper>
  );
};

export default UnleashPower;
