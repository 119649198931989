import DocsList from './components/DocsList/DocsList';
import {DocsPageContainer} from './styles';

const DocsPage = () => {
  return (
    <DocsPageContainer>
      <DocsList />
    </DocsPageContainer>
  );
};

export default DocsPage;
