import React, {useContext, useMemo} from 'react';
import {ThemeProvider} from '@mui/material';
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import {THEME} from '@constants';
import darkTheme from '@themes/darkTheme';
import lightTheme from '@themes/lightTheme';
import {GlobalContext} from '../context/global';
const InitTheme = ({children}: {children: React.ReactNode}) => {
  const {
    globalContextValue: {theme},
  } = useContext(GlobalContext);

  const selectedTheme = useMemo(
    () => (theme === THEME.light ? lightTheme : darkTheme),
    [theme],
  );

  return (
    <ThemeProvider theme={selectedTheme}>
      <StyledThemeProvider theme={selectedTheme}>
        {children}
      </StyledThemeProvider>
    </ThemeProvider>
  );
};

export default InitTheme;
