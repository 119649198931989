import {Container} from '@mui/material';
import styled from 'styled-components';

export const DocsPageContainer = styled(Container)`
  margin: 100px 0 50px 0;

  ${({theme}) => theme.breakpoints.down('sm')} {
    margin-top: 75px;
  }
`;
