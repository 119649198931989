import React, {useEffect, useRef} from 'react';
import {Box, Theme, useMediaQuery, useTheme} from '@mui/material';
import {useCountUp} from 'react-countup';
import Typography from '@components/Typography/Typography';
import {useInViewPort} from '@hooks/useInViewPort';
import CodeBlock from './CodeBlock/CodeBlock';
import {SwitchComponentPropsTypes} from './TurbochargeYourCode.types';
import {StatsWrapper, SwitchWrapper, ThunderSwitch} from './styles';

const SwitchComponent = ({
  checked,
  theme,
  handleChange,
}: SwitchComponentPropsTypes) => {
  return (
    <SwitchWrapper>
      <ThunderSwitch onChange={handleChange} checked={checked} />
      <Typography mt={4} variant="h3" bold>
        <span style={{color: theme.palette.primary.main, fontWeight: 'normal'}}>
          {'<turbocharge>'}
        </span>
        <br />
        your code
      </Typography>
    </SwitchWrapper>
  );
};
const Description = ({theme}: {theme: Theme}) => {
  return (
    <Typography
      mt={4}
      fontSize={18}
      fontWeight={400}
      color={theme.palette.info.contrastText}>
      Powertools takes care of the heavy lifting, ensuring that your serverless
      applications adhere to industry-leading best practices. Say goodbye to
      tedious manual configuration and hello to automated optimization.
      Powertools for AWS effortlessly implements key serverless practices,
      including optimized resource allocation, efficient event-driven
      architecture, and streamlined scalability.
    </Typography>
  );
};

const Stats = ({checked}: {checked: boolean}) => {
  const START_LINE_COUNT = 0;
  const END_LINE_COUNT = 50;
  const START_PERCENTAGE_COUNT = 0;
  const END_PERCENTAGE_COUNT = 60;
  const {start: startLineCounter, reset: resetLineCounter} = useCountUp({
    ref: 'lines-counter',
    start: START_LINE_COUNT,
    end: END_LINE_COUNT,
    startOnMount: false,
  });
  const {start: startPercentageCounter, reset: resetPercentageCounter} =
    useCountUp({
      ref: 'percentage-counter',
      start: START_PERCENTAGE_COUNT,
      end: END_PERCENTAGE_COUNT,
      startOnMount: false,
      suffix: '%',
    });

  useEffect(() => {
    if (checked) {
      startLineCounter();
      startPercentageCounter();
    } else {
      resetLineCounter();
      resetPercentageCounter();
    }
  }, [checked]);

  return (
    <StatsWrapper mt={4} checked={checked}>
      <Box className={'lines-of-code'}>
        <Typography variant="h3">
          <span className={'strike-through'}>125</span>
          <span id="lines-counter" />
        </Typography>
        <Typography>Total lines of code</Typography>
      </Box>
      <Box className={'reduced'}>
        <Typography variant="h3">
          <span id={'percentage-counter'} />
        </Typography>
        <Typography>Lines reduced</Typography>
      </Box>
    </StatsWrapper>
  );
};

const TurbochargeYourCode = () => {
  const theme = useTheme();
  const turboBlockRef = useRef<HTMLDivElement>(null);
  const isVisible = useInViewPort(turboBlockRef);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (isVisible) {
      setChecked(true);
    }
  }, [isVisible]);

  return (
    <div ref={turboBlockRef}>
      {isMobile ? (
        <Box display={'column'}>
          <SwitchComponent
            checked={checked}
            theme={theme}
            handleChange={handleChange}
          />
          <CodeBlock isMobile={true} theme={theme} checked={checked} />
          <Stats checked={checked} />
          <Description theme={theme} />
        </Box>
      ) : (
        <Box
          display={'flex'}
          mt={15}
          flexWrap={'wrap'}
          justifyContent={'space-between'}>
          <Box
            mt={10}
            pr={8}
            flexBasis={'30%'}
            sx={{flexBasis: {xs: '100%', md: '30%'}}}>
            <SwitchComponent
              checked={checked}
              theme={theme}
              handleChange={handleChange}
            />
            <Description theme={theme} />
            <Stats checked={checked} />
          </Box>
          <CodeBlock theme={theme} checked={checked} />
        </Box>
      )}
    </div>
  );
};

export default TurbochargeYourCode;
