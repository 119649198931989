import {useState} from 'react';
import {Box, Link, useMediaQuery, useTheme} from '@mui/material';
import Typography from '@components/Typography/Typography';
import {THEME, openSourceLinks} from '@constants';
import DocsListWrapper from './styles';

const OpenSourceFreedom = () => {
  const {palette, breakpoints} = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const [, setActiveLogo] = useState<string | null>(null);
  const isDarkTheme = palette.mode === THEME.dark;

  const activeIconColor = isDarkTheme ? '#fff' : '#3178C6';

  return (
    <DocsListWrapper>
      <Typography variant="h3" className="title-text" mt={4}>
        Check out the documentation
      </Typography>

      <Box className="logos-container" mt={isMobile ? 4 : 8}>
        {openSourceLinks.map(({id, title, LightActiveIcon, docsLink}) => {
          return (
            <Link key={title} href={docsLink} target="_blank" underline="none">
              <Box
                className={`logo-box`}
                onMouseEnter={() => setActiveLogo(id)}
                onMouseLeave={() => setActiveLogo(null)}>
                <LightActiveIcon
                  className="language-icon fade-in"
                  fill={activeIconColor}
                  width={isMobile ? 50 : 160}
                  height={isMobile ? 50 : 160}
                />
                <Typography mt={2} bold color={'black'}>
                  {title}
                </Typography>
                <Typography
                  className="github-button"
                  textAlign={'center'}
                  mt={0.5}
                  color={'primary'}
                  bold
                  variant="subtitle2">
                  {'View Documentation'}
                </Typography>
              </Box>
            </Link>
          );
        })}
      </Box>
    </DocsListWrapper>
  );
};

export default OpenSourceFreedom;
