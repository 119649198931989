import {Box} from '@mui/material';
import styled from 'styled-components';
import {THEME} from '@constants';

export const SwitchWrapper = styled(Box)`
  background: ${({theme}) =>
    theme.palette.mode === THEME.dark
      ? theme.palette.background.default
      : theme.palette.info.main};
  border: ${({theme}) =>
    theme.palette.mode === THEME.dark
      ? `1px solid ${theme.palette.info.dark}`
      : ''};
  padding: 5px;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  border-radius: 10px;
`;
