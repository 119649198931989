import MuiButton, {ButtonProps} from '@mui/material/Button';

const Button = (props: ButtonProps) => {
  const {children} = props;

  return (
    <MuiButton variant="contained" {...props}>
      {children}
    </MuiButton>
  );
};

export default Button;
