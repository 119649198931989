import {Box, lighten} from '@mui/material';
import styled from 'styled-components';

export const InstallWrapper = styled(Box)`
  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;

    ${({theme}) => theme.breakpoints.down('sm')} {
      width: 100%;
    }

    svg {
      flex: 1;
    }

    .install-btn {
      margin: 0 10px;
      ${({theme}) => theme.breakpoints.down('sm')} {
        margin: 0 0;
        width: 100%;
      }
    }
  }
  & svg:first-child,
  svg:last-child {
    width: 30%;
    ${({theme}) => theme.breakpoints.down('md')} {
      width: 20%;
    }
    ${({theme}) => theme.breakpoints.down('sm')} {
      display: none;
    }
  }
  .description {
    width: 50%;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: ${({theme}) => lighten(theme.palette.text.primary, 0.3)};
    ${({theme}) => theme.breakpoints.down('sm')} {
      width: 100%;
    }
  }
`;
