import person1Small from '@images/testimonial-person-1-image-small.png';
import person1 from '@images/testimonial-person-1-image.png';
import person2Small from '@images/testimonial-person-2-image-small.png';
import person2 from '@images/testimonial-person-2-image.png';
import {ReactComponent as CapitalOneSvg} from '@svgs/capital-one.svg';
import {ReactComponent as CyberarkSvg} from '@svgs/cyberark.svg';
import {ReactComponent as DotnetSvg} from '@svgs/dotnet.svg';
import {ReactComponent as JavaColoredDarkSvg} from '@svgs/java-colored-dark.svg';
import {ReactComponent as JavaColoredSvg} from '@svgs/java-colored.svg';
import {ReactComponent as JavaDarkSvg} from '@svgs/java-dark.svg';
import {ReactComponent as JavaSvg} from '@svgs/java.svg';
import {ReactComponent as PythonColoredSvg} from '@svgs/python-colored.svg';
import {ReactComponent as PythonSvg} from '@svgs/python.svg';
import {ReactComponent as TypescriptSvg} from '@svgs/typescript.svg';

export const languages = [
  {
    id: 'python',
    name: 'Python',
    Icon: PythonSvg,
    steps: [
      {
        label: 'Lambda Layer (x86_64)',
        command:
          'arn:aws:lambda:{region}:017000801446:layer:AWSLambdaPowertoolsPythonV2:78',
      },
      {
        label: 'Lambda Layer (arm64)',
        command:
          'arn:aws:lambda:{region}:017000801446:layer:AWSLambdaPowertoolsPythonV2-Arm64:78',
      },
      {
        label: 'Pip',
        command: `pip install "aws-lambda-powertools"`,
      },
    ],
  },
  {
    id: 'typescript',
    name: 'TypeScript',
    Icon: TypescriptSvg,
    steps: [
      {
        label: 'Lambda Layer',
        command:
          'arn:aws:lambda:{region}:094274105915:layer:AWSLambdaPowertoolsTypeScriptV2:12',
      },
      {
        label: 'Logger',
        command: 'npm install @aws-lambda-powertools/logger',
      },
      {
        label: 'Metrics',
        command: 'npm install @aws-lambda-powertools/metrics',
      },
      {
        label: 'Tracer',
        command: 'npm install @aws-lambda-powertools/tracer',
      },
      {
        label: 'Parser (new)',
        command: 'npm install @aws-lambda-powertools/parser zod@~3',
      },
      {
        label: 'Idempotency',
        command:
          'npm i @aws-lambda-powertools/idempotency @aws-sdk/client-dynamodb @aws-sdk/lib-dynamodb',
      },
      {
        label: 'Batch Processing',
        command: 'npm i @aws-lambda-powertools/batch',
      },
      {
        label: 'Parameters with SSM',
        command:
          'npm install @aws-lambda-powertools/parameters @aws-sdk/client-ssm',
      },
      {
        label: 'Parameters with Secrets Manager',
        command:
          'npm install @aws-lambda-powertools/parameters @aws-sdk/client-secrets-manager',
      },
      {
        label: 'Parameters with AppConfig',
        command:
          'npm install @aws-lambda-powertools/parameters @aws-sdk/client-appconfigdata',
      },
    ],
  },
  {
    id: 'java',
    name: 'Java',
    Icon: JavaSvg,
    DarkIcon: JavaDarkSvg,
    steps: [
      {
        label: 'Maven',
        command: `<dependencies>
  ...
  <dependency>
      <groupId>software.amazon.lambda</groupId>
      <artifactId>powertools-tracing</artifactId>
      <version>1.18.0</version>
  </dependency>
  <dependency>
      <groupId>software.amazon.lambda</groupId>
      <artifactId>powertools-logging</artifactId>
      <version>1.18.0</version>
  </dependency>
  <dependency>
      <groupId>software.amazon.lambda</groupId>
      <artifactId>powertools-metrics</artifactId>
      <version>1.18.0</version>
  </dependency>
  ...
</dependencies>`,
      },
    ],
  },
  {
    id: 'dotnet',
    name: '.NET',
    Icon: DotnetSvg,
    steps: [
      {
        label: 'AWS.Lambda.Powertools.Logging',
        command: 'dotnet add package AWS.Lambda.Powertools.Logging',
      },
      {
        label: 'AWS.Lambda.Powertools.Metrics',
        command: 'dotnet add package AWS.Lambda.Powertools.Metrics',
      },
      {
        label: 'AWS.Lambda.Powertools.Tracing',
        command: 'dotnet add package AWS.Lambda.Powertools.Tracing',
      },
      {
        label: 'AWS.Lambda.Powertools.Parameters',
        command: 'dotnet add package AWS.Lambda.Powertools.Parameters',
      },
      {
        label: 'AWS.Lambda.Powertools.Idempotency',
        command: 'dotnet add package AWS.Lambda.Powertools.Idempotency',
      },
      {
        label: 'AWS.Lambda.Powertools.BatchProcessing',
        command: 'dotnet add package AWS.Lambda.Powertools.BatchProcessing',
      },
    ],
  },
];

export const CARDS = [
  {
    id: 1,
    personImage: person1,
    personImageSmall: person1Small,
    Logo: CapitalOneSvg,
    lightModeColor: 'black',
    darkModeColor: 'white',
    personName: 'Daniel Furman',
    personTitle: 'Distinguished Engineer at Capital One',
    feedback:
      '         Capital One has widely-adopted Powertools for AWS for projects\n' +
      '                  across the enterprise. Powertools allows us to implement\n' +
      '                  serverless best practices by leveraging the boundless\n' +
      '                  resources provided by the global open source community.\n' +
      '                  Whether it’s an organization’s first serverless project or\n' +
      '                  development of a new serverless platform, Powertools for AWS\n' +
      '                  can help companies improve developer productivity and easily\n' +
      '                  implement common best practice tasks.',
    isActive: true,
  },
  {
    id: 2,
    personImage: person2,
    personImageSmall: person2Small,
    Logo: CyberarkSvg,
    lightModeColor: '#173159',
    darkModeColor: 'white',
    personName: 'Ran Isenberg',
    personTitle: 'Principal Software Architect at CyberArk',
    feedback: `
    Diving into the world of serverless? Powertools for AWS Lambda is a game-changer! It's like the Swiss Army knife for our serverless journey. Honestly, I can't imagine writing serverless without it anymore.
    `,
    isActive: false,
  },
];

export const ROUTER_PATHS = {
  INSTALL_POWERTOOL_FOR_AWS: '/install-powertool-for-aws',
};

export const containerIds = {
  installPowertoolsForAws: '#install-powertools-for-aws',
};

export const appURLs = {
  // Nav and footer
  documentationURL: '/docs',
  githubURL: 'https://github.com/aws-powertools/',

  // header
  installPowertoolsURL: ROUTER_PATHS.INSTALL_POWERTOOL_FOR_AWS,

  joinUsURL: 'https://www.example.com',

  // Documentation base url
  documentationBaseUrl: 'https://docs.powertools.aws.dev/lambda',

  // How powertools for AWS works section
  howPowetoolsWorksVideoURL: 'https://www.example.com',
  howPowetoolsWorksYoutubeVideoURL: 'https://www.example.com',

  // Open source freedom section
  pythonGithubURL: 'https://github.com/aws-powertools/powertools-lambda-python',
  javaGithubURL: 'https://github.com/aws-powertools/powertools-lambda-java',
  typescriptGithubURL:
    'https://github.com/aws-powertools/powertools-lambda-typescript',
  dotnetGithubURL: 'https://github.com/aws-powertools/powertools-lambda-dotnet',

  // Footer
  privacyPolicyURL: 'https://aws.amazon.com/privacy/',
  termsOfServiceURL: 'https://aws.amazon.com/service-terms/',
  discordURL: 'https://discord.gg/B8zZKbbyET',
};

export const navLinks = [
  {label: 'Documentation', link: appURLs.documentationURL},
  {label: 'Github', link: appURLs.githubURL},
  {label: 'Join Community', link: appURLs.discordURL},
];

export const getFooterLinks = (isMobile: boolean) => [
  {label: 'Documentation', link: appURLs.documentationURL, target: '_self'},
  {label: 'Github', link: appURLs.githubURL},
  {label: 'Join Community', link: appURLs.discordURL},
  {
    label: 'Install Powertools',
    link: isMobile
      ? appURLs.installPowertoolsURL
      : containerIds.installPowertoolsForAws,
    target: '_self',
  },
  {label: 'Privacy Policy', link: appURLs.privacyPolicyURL},
  {label: 'Terms of Service', link: appURLs.termsOfServiceURL},
];

// Enums
export enum FOOTER_LOC {
  layout = 'layout',
  menu = 'menu',
}
export enum THEME {
  light = 'light',
  dark = 'dark',
}

export enum LOCAL_STORAGE_KEYS {
  themeMode = 'themeMode',
}

export const openSourceLinks = [
  {
    id: 'python',
    title: 'Python',
    Icon: PythonSvg,
    DarkActiveIcon: PythonSvg,
    LightActiveIcon: PythonColoredSvg,
    githubLink: appURLs.pythonGithubURL,
    docsLink: `${appURLs.documentationBaseUrl}/python/latest/`,
  },
  {
    id: 'typeScript',
    title: 'TypeScript',
    Icon: TypescriptSvg,
    LightActiveIcon: TypescriptSvg,
    DarkActiveIcon: TypescriptSvg,
    githubLink: appURLs.typescriptGithubURL,
    docsLink: `${appURLs.documentationBaseUrl}/typescript/latest/`,
  },
  {
    id: 'java',
    title: 'Java',
    Icon: JavaSvg,
    DarkIcon: JavaDarkSvg,
    LightActiveIcon: JavaColoredSvg,
    DarkActiveIcon: JavaColoredDarkSvg,
    githubLink: appURLs.javaGithubURL,
    docsLink: `${appURLs.documentationBaseUrl}/java/latest/`,
  },
  {
    id: 'dotnet',
    title: '.NET',
    Icon: DotnetSvg,
    LightActiveIcon: DotnetSvg,
    DarkActiveIcon: DotnetSvg,
    githubLink: appURLs.dotnetGithubURL,
    docsLink: `${appURLs.documentationBaseUrl}/dotnet/latest/`,
  },
];
