import {useContext} from 'react';
import {
  Box,
  Divider,
  Link as MuiLink,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {Link} from 'react-router-dom';
import makeenLogo from '@images/makeen-light.svg';
import {FooterTypes} from '@components/Footer/Footer.types';
import ThemeBtnSwitch from '@components/ThemeBtnSwitch/ThemeBtnSwitch';
import {LOCAL_STORAGE_KEYS, THEME, getFooterLinks} from '@constants';
import {GlobalContext} from '../../context/global';
import {FooterWrapper, StyledDiscord, StyledGitHub} from './styles';

const Footer = ({mobileMenuOpened = false}: FooterTypes) => {
  const currentYear = new Date().getFullYear();
  const {breakpoints} = useTheme();
  const isTab = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const {globalContextValue, setGlobalContextValue} = useContext(GlobalContext);

  const handleSwitchTheme = (theme: THEME) => {
    setGlobalContextValue((prevState) => {
      const newState = {
        ...prevState,
        theme,
      };

      localStorage.setItem(LOCAL_STORAGE_KEYS.themeMode, theme);

      return newState;
    });
  };

  return (
    <FooterWrapper>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}>
        <Box>
          {!mobileMenuOpened && (
            <Typography className={'power-tools'}>
              Powertools for AWS
            </Typography>
          )}

          <Box
            display={'flex'}
            justifyContent={'space-between'}
            flexDirection={isTab ? 'column' : 'row'}
            mt={mobileMenuOpened ? 5 : 0}>
            <Box className={'footer-links-wrapper'}>
              {getFooterLinks(isMobile).map(({link, label, target}) => (
                <MuiLink
                  my={1}
                  key={label}
                  to={link}
                  className={'link'}
                  target={target ?? '_blank'}
                  component={Link}>
                  <Typography variant="subtitle2" color={'text.disabled'}>
                    {label}
                  </Typography>
                </MuiLink>
              ))}
            </Box>
            <Box display={'flex'} alignItems={'center'} mt={isTab ? 3 : 0}>
              <StyledGitHub />
              <StyledDiscord />
              {!isTab && (
                <ThemeBtnSwitch
                  modeSwitch={globalContextValue.theme}
                  setModeSwitch={handleSwitchTheme}
                />
              )}
            </Box>
          </Box>
        </Box>

        {!mobileMenuOpened && <Divider className={'divider'} />}

        <Box my={2} className={'copy-rights-wrapper'}>
          <Typography className={'copy-rights'}>
            © {currentYear}, Amazon Web Services, Inc. or its affiliates. All
            rights reserved.
          </Typography>
          <Box className={`${mobileMenuOpened ? 'makeen-logo-wrapper' : ''}`}>
            {mobileMenuOpened && (
              <ThemeBtnSwitch
                modeSwitch={globalContextValue.theme}
                setModeSwitch={handleSwitchTheme}
              />
            )}
            <img
              src={makeenLogo}
              alt="Makeen logo"
              style={mobileMenuOpened ? {marginTop: 0} : {}}
            />
          </Box>
        </Box>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
