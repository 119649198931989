import Box from '@mui/material/Box';
import styled, {css} from 'styled-components';
import {ReactComponent as capitalOneLogo} from '@images/capital-one-logo.svg';
import {ReactComponent as caylentLogo} from '@images/caylent.svg';
import {ReactComponent as cpqiLogo} from '@images/cpgi-logo.svg';
import {ReactComponent as cyberarkLogo} from '@images/cyberark-logo.svg';
import {ReactComponent as hashnodeLogo} from '@images/hashnode-logo.svg';
import {ReactComponent as trek10Logo} from '@images/trek10-logo.svg';

export const LogosWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;

  ${({theme}) => theme.breakpoints.down('md')} {
    width: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const OpenSourceWrapper = styled(Box)`
  width: 195px;
  height: 195px;
  position: relative;

  & img:first-child {
    width: inherit;
    height: inherit;
    animation: rotating 30s linear infinite;
  }
  & img:last-child {
    width: calc(100% / 2);
    height: calc(100% / 2);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    vertical-align: middle;
  }
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const UnleashPowerWrapper = styled(Box)`
  h3 {
    font-size: 72px;
    font-weight: 700;
    .serverless {
      font-weight: 400;
      font-family: var(--PTMono);
    }
    span {
      position: relative;
      .stripes {
        width: 100%;
        height: 25px;
        position: absolute;
        bottom: -20px;
        left: 0;
        ${({theme}) => theme.breakpoints.down('md')} {
          display: none;
        }
      }
    }
    ${({theme}) => theme.breakpoints.down('md')} {
      font-size: 40px;
    }
  }
`;

const svgFill = css`
  path {
    fill: ${({theme}) => theme.palette.text.primary};
  }
`;
export const StyledCapitalOneLogo = styled(capitalOneLogo)`
  ${svgFill}
`;
export const StyledCaylentLogo = styled(caylentLogo)`
  ${svgFill}
`;
export const StyledCPQILogo = styled(cpqiLogo)`
  ${svgFill}
`;
export const StyledCyberarkLogo = styled(cyberarkLogo)`
  ${svgFill}
`;
export const StyledHashnodeLogo = styled(hashnodeLogo)`
  ${svgFill}
`;
export const StyledTrek10Logo = styled(trek10Logo)`
  ${svgFill}
`;
