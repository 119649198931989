import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {Link as MuiLink} from '@mui/material';
import {useTheme} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import {Link} from 'react-router-dom';
import Typography from '@components/Typography/Typography';
import {containerIds, navLinks} from '@constants';
import MobileMenu from './MobileMenu';
import {NavbarTypes} from './Navbar.props';
import {NavBarContainer, NavItemsWrapper} from './styles';

const Navbar = ({onDrawerToggle, isMobileMenuOpen}: NavbarTypes) => {
  const theme = useTheme();

  return (
    <NavBarContainer id="navbar-container">
      <AppBar component="nav" elevation={0}>
        <Toolbar>
          <Typography sx={{flexGrow: 1}} onClick={() => onDrawerToggle(false)}>
            <Link
              to={'/'}
              style={{textDecoration: 'none'}}
              className={'proj-name'}>
              Powertools for AWS
            </Link>
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => onDrawerToggle()}
            sx={{display: {md: 'none'}}}>
            {isMobileMenuOpen ? (
              <CloseIcon style={{color: theme.palette.text.primary}} />
            ) : (
              <MenuIcon style={{color: theme.palette.text.primary}} />
            )}
          </IconButton>
          <NavItemsWrapper>
            {navLinks.map((item) => {
              const isExternal = item.link.startsWith('http');
              return (
                <MuiLink
                  key={item.label}
                  to={item.link}
                  component={Link}
                  target={isExternal ? '_blank' : '_self'}
                  color={'inherit'}>
                  <Button className={'navItems'} color="inherit">
                    {item.label}
                  </Button>
                </MuiLink>
              );
            })}

            <MuiLink to={containerIds.installPowertoolsForAws} component={Link}>
              <Button variant="contained" className="install-button">
                {'Install Powertools'}
              </Button>
            </MuiLink>
          </NavItemsWrapper>
        </Toolbar>
      </AppBar>
      {isMobileMenuOpen && <MobileMenu toggleMobileMenu={onDrawerToggle} />}
    </NavBarContainer>
  );
};

export default Navbar;
