export const strikeThroughLineNumbers: number[] = [
  2, 3, 5, 9, 10, 12, 16, 17, 20, 21, 23, 24, 28, 29, 32, 34, 39, 40, 41, 42,
  49, 50, 51, 52, 53, 53, 54, 55, 56, 58, 66, 67, 68, 69, 70, 71, 72, 73, 89,
  94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110,
  111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125,
];
export const code = `import json
import logging
import os
import boto3
from aws_xray_sdk.core import xray_recorder
from aws_xray_sdk.core import patch

# Configure the logger
logging.basicConfig(format='%(asctime)s - %(name)s - 
%(levelname)s - %(message)s')
logger = logging.getLogger()
logger.setLevel(logging.INFO)

# Configure AWS X-Ray
patch(['boto3'])
xray_recorder.configure(service='my-lambda-function')
xray_recorder.configure(context_missing='LOG_ERROR')
xray_recorder.configure(streaming_threshold=0)

# Configure CloudWatch
cloudwatch = boto3.client('cloudwatch')

# Set cold start to true
is_cold_start = True

# Create a function to publish CloudWatch custom metrics
def publish_custom_metric(value, metric_name):
    cloudwatch.put_metric_data(
        Namespace='WebsiteExample',
        MetricData=[
            {
                'MetricName': metric_name,
                'Value': value,
                'Unit': 'Count'
            }
        ]
    )

def is_valid_payload(payload):
    # Check if the payload resembles an AWS API Gateway event
    return all(key in payload for key in ('httpMethod',
    'path', 'queryStringParameters'))

def lambda_handler(event, context):

    # Initialize a trace segment
    with xray_recorder.in_subsegment("## Lambda Handler ##") 
    as subsegment:
        # Check if the function is a cold start and create a 
        metric
        global is_cold_start
        if not is_cold_start:
            logger.info("Cold start detected. 
            Initializing...")
            publish_custom_metric(is_cold_start, "ColdStart")
            is_cold_start = False

            subsegment.put_annotation("ColdStart", "True")

        # Check if the event resembles an AWS API Gateway 
        event
        if is_valid_payload(event):
            # Check the HTTP method of the request
            http_method = event['httpMethod']

            # Default response content
            response = {
                "statusCode": 200,
                "headers": {
                    "Content-Type": "application/json"
                },
                "body": ""
            }

            # Retrieve the "id_todo" variable from the query 
            parameters in the GET method
            if http_method == "GET" and event['path'] == "/
            example":
                id_todo = event['queryStringParameters']
                ['id_todo']
                response["body"] = json.dumps({"message": 
                f"This is the GET route /example, id_todo: 
                {id_todo}"})
                log_context = {
                    "http_method": http_method,
                    "path": event['path'],
                    "id_todo": id_todo
                }
                logger.info(json.dumps(log_context))
                # Publish a custom metric for the GET request
                publish_custom_metric(1, "GETRequestCount")
                subsegment.put_metadata("response", 
                log_context)
            # Receive a payload with "id_todo" and 
            "name_todo" in the POST method
            elif http_method == "POST" and event['path'] == 
            "/example":
                request_body = json.loads(event['body'])
                id_todo = request_body.get('id_todo')
                name_todo = request_body.get('name_todo')
                log_context = {
                    "http_method": http_method,
                    "path": event['path'],
                    "request_data": request_body
                }
                # Verify if the payload fields are filled
                if id_todo and name_todo:
                    response["body"] = json.dumps({"message": 
                    f"This is the POST route /example, 
                    id_todo: {id_todo}, name_todo: 
                    {name_todo}"})
                    logger.info(json.dumps(log_context))
                    # Publish a custom metric for the POST 
                    request
                    publish_custom_metric(1, 
                    "POSTRequestCount")
                    subsegment.put_metadata("response", 
                    log_context)
                else:
                    response["statusCode"] = 400  # Bad 
                    Request
                    response["body"] = json.dumps({"message": 
                    "Missing 'id_todo' or 'name_todo' in the 
                    payload"})
                    logger.warning(json.dumps(log_context))
            else:
                # Handle other methods or paths, if needed
                response["statusCode"] = 404
                response["body"] = json.dumps({"message": 
                "Route not found"})
                log_context = {
                    "http_method": http_method,
                    "path": event['path'],
                    "message": "Route not found"
                }
                logger.warning(json.dumps(log_context))
        else:
            # Handle cases where the payload doesn't resemble 
            an AWS API Gateway event
            response = {
                "statusCode": 400,
                "headers": {
                    "Content-Type": "application/json"
                },
                "body": json.dumps({"message": "Invalid 
                payload format"})
            }
            logger.warning("Invalid payload format")

    return response
`;

export const optimizedCode = `# Import necessary libraries and modules
from aws_lambda_powertools.metrics import MetricUnit
from aws_lambda_powertools import Logger, Metrics, Tracer
from aws_lambda_powertools.event_handler import 
APIGatewayRestResolver, Response, content_types
from aws_lambda_powertools.logging import correlation_paths
from aws_lambda_powertools.event_handler.exceptions import 
NotFoundError
from aws_lambda_powertools.utilities.data_classes import 
APIGatewayProxyEvent
from aws_lambda_powertools.utilities.typing import 
LambdaContext

# Initialize the Logger, Metrics, and Tracer components
logger = Logger()
metrics = Metrics(namespace="WebsiteExample")
tracer = Tracer()

# Initialize the API Gateway Rest Resolver
app = APIGatewayRestResolver()

# Define a handler for handling "not found" errors
@app.not_found
@tracer.capture_method
def handle_not_found_errors(exc: NotFoundError) -> Response:
    logger.debug("Route not found", 
    route=app.current_event.path)
    return Response(status_code=404, 
    content_type=content_types.TEXT_PLAIN, body="Not found")

# Define a GET route for the "/example" path
@app.get("/example/<id_todo>")
@tracer.capture_method
def get_example(id_todo) -> Response:
    logger.info("This is the GET route /example", 
    id_todo=id_todo)
    metrics.add_metric(name="GETRequestCount", 
    unit=MetricUnit.Count, value=1)
    return Response(status_code=200,  
    content_type=content_types.APPLICATION_JSON, body=f"This 
    is the GET route /example, id_todo: {id_todo}")

# Define a POST route for the "/example" path
@app.post("/example")
@tracer.capture_method
def post_example():
    id_todo = app.current_event.json_body.get("id_todo")
    name_todo = app.current_event.json_body.get("name_todo")
    logger.info("This is the POST route /example", 
    id_todo=id_todo, name_todo=name_todo)
    metrics.add_metric(name="POSTRequestCount", 
    unit=MetricUnit.Count, value=1)
    return Response(status_code=200, 
    content_type=content_types.APPLICATION_JSON, body=f"This 
    is the POST route /example, id_todo: {id_todo}")

# Define the main Lambda handler function with logs, metrics, 
tracers and validation
@tracer.capture_lambda_handler()
@metrics.log_metrics(capture_cold_start_metric=True)
@logger.inject_lambda_context(correlation_id_path=correlation
_paths.API_GATEWAY_REST)
def lambda_handler(event: APIGatewayProxyEvent, context: 
LambdaContext):
    # Resolve the event using the API Gateway Rest Resolver
    return app.resolve(event, context)`;
