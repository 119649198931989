import {useEffect, useRef, useState} from 'react';
import {Box, useMediaQuery, useTheme} from '@mui/material';
import {useLocation} from 'react-router-dom';
import CopyText from '@components/CopyText/CopyText';
import Typography from '@components/Typography/Typography';
import {THEME, containerIds, languages} from '@constants';
import {InstallLanguageWrapper} from './styles';

const InstallLanguage = () => {
  const {palette, breakpoints} = useTheme();
  const [activeTab, setActiveTab] = useState(languages[0].id);
  const isDarkTheme = palette.mode === THEME.dark;
  const inActiveIconColor = isDarkTheme ? '#4F5965' : '#D6D6D6';
  const activeIconColor = isDarkTheme ? '#fff' : '#232F3F';
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const location = useLocation();

  useEffect(() => {
    const {hash} = location;

    if (
      !isMobile &&
      containerRef.current &&
      hash === containerIds.installPowertoolsForAws
    ) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [location]);

  return (
    <InstallLanguageWrapper
      mt={isMobile ? 0 : 16}
      id={containerIds.installPowertoolsForAws.substring(1)}
      ref={containerRef}>
      <Typography
        variant="h3"
        className="title-text"
        textAlign={isMobile ? 'left' : 'center'}>
        <span>{'<install>'}</span>
        <br />
        Powertools for AWS
      </Typography>

      <Box className="tabs-container" mt={6}>
        <Box className="tabs">
          {languages.map(({id, name, Icon, DarkIcon}) => {
            const isActive = activeTab === id;

            // if there's a different Icon for dark mode
            if (isDarkTheme && DarkIcon) {
              Icon = DarkIcon;
            }

            return (
              <Box
                key={id}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                className="tab-container">
                {!isMobile && (
                  <Icon
                    className="language-icon"
                    height={50}
                    fill={isActive ? activeIconColor : inActiveIconColor}
                  />
                )}
                <Box className={'tab'} mt={4} onClick={() => setActiveTab(id)}>
                  <Box className={`tabWrapper ${isActive ? 'active' : ''}`}>
                    <Typography textAlign={'center'} bold>
                      {name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>

        <Box className="content-container" mt={isMobile ? 0 : 6.5}>
          <Box px={isMobile ? 0 : 6} py={1}>
            {languages
              .find((x) => activeTab === x.id)
              ?.steps.map((step) => (
                <Box key={step.label} my={5}>
                  <Typography mb={1} bold>
                    {step.label}
                  </Typography>
                  <Box
                    className="command"
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    flexDirection={'row'}
                    gap={2}>
                    <Box className="command-text-container">
                      <Typography
                        className="space-mono-font-family"
                        component={'pre'}>
                        {step.command}
                      </Typography>
                    </Box>
                    <Box display={'flex'} alignContent={'center'}>
                      <CopyText text={step.command} />
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </InstallLanguageWrapper>
  );
};

export default InstallLanguage;
