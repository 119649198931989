import {Box} from '@mui/material';
import styled from 'styled-components';

const OpenSourceFreedomWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-text {
    width: 50%;
    text-align: center;
    font-weight: 800;
    ${({theme}) => theme.breakpoints.down('md')} {
      width: 100%;
    }
  }

  .logos-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    .logo-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0;
      width: 170px;
      margin: 10px 30px;
      border-radius: 8px;
      font-weight: bold;
      ${({theme}) => theme.breakpoints.down('md')} {
        width: 40%;
      }
      ${({theme}) => theme.breakpoints.down('sm')} {
        margin: 10px;
      }
      .github-button {
        visibility: hidden;
      }
    }

    ${({theme}) => theme.breakpoints.up('sm')} {
      :hover {
        transition: background-color 0.8s;
        background-color: ${({theme}) => theme.palette.background.paper};
        .logo-box {
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
        }
        .github-button {
          visibility: visible;
        }
      }
    }
  }
`;

export default OpenSourceFreedomWrapper;
