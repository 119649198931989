import {useState} from 'react';
import {CheckCircle} from '@mui/icons-material';
import {ReactComponent as CopyIcon} from '@svgs/copy-icon.svg';
import CopyTextProps from './CopyText.props';

const CopyText = ({text, iconSize = 22, afterCopied}: CopyTextProps) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleClick = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2500);

    afterCopied && afterCopied();
  };

  return (
    <>
      {isCopied ? (
        <CheckCircle
          data-testid="copied-icon"
          fontSize="small"
          color={'success'}
        />
      ) : (
        <CopyIcon
          onClick={handleClick}
          data-testid="copy-icon"
          width={iconSize}
          height={iconSize}
          color={'primary'}
          cursor={'pointer'}
        />
      )}
    </>
  );
};

export default CopyText;
