import {Box, Link as MuiLink, useMediaQuery, useTheme} from '@mui/material';
import {Link} from 'react-router-dom';
import {ReactComponent as FocusArrowDown} from '@svgs/focus-arrow-down.svg';
import {ReactComponent as FocusArrowUp} from '@svgs/focus-arrow-up.svg';
import Button from '@components/Button/Button';
import Typography from '@components/Typography/Typography';
import {appURLs, containerIds} from '@constants';
import GetGoingWrapper from './styles';

const GetGoing = () => {
  const {palette, breakpoints} = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <GetGoingWrapper>
      <Box flex={1}>
        <Typography variant="h2" fontWeight={'900'}>
          Get going with <br />
          <span className="primary-text">Powertools</span> <br />
          for AWS
        </Typography>
        <Typography mt={5} className="pt-mono-font-family">
          {`<it’s free>`}
        </Typography>
      </Box>
      <Box flex={1}>
        <Box className="buttons-container">
          <FocusArrowDown
            className="focus-arrow-down"
            stroke={palette.info.main}
          />
          <MuiLink
            component={Link}
            to={
              isMobile
                ? appURLs.installPowertoolsURL
                : containerIds.installPowertoolsForAws
            }
            width={'100%'}>
            <Button fullWidth>Install Powertools for AWS</Button>
          </MuiLink>

          <MuiLink
            color="inherit"
            component={Link}
            to={appURLs.discordURL}
            width={'100%'}
            target="_blank">
            <Button variant="outlined" color="inherit" fullWidth>
              Join community
            </Button>
          </MuiLink>
          <FocusArrowUp className="focus-arrow-up" stroke={palette.info.main} />
        </Box>
      </Box>
    </GetGoingWrapper>
  );
};

export default GetGoing;
