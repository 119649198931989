import {Box} from '@mui/material';
import styled from 'styled-components';

const GetGoingWrapper = styled(Box)`
  display: flex;
  flex-flow: row;
  padding: 40px 100px;
  align-items: center;
  margin-top: 100px;
  background-color: ${({theme}) => theme.palette.background.paper};
  box-shadow: 0px 4px 10px 0px #0000000d;
  border-radius: 8px;

  ${({theme}) => theme.breakpoints.down('md')} {
    padding: 50px 20px;
    flex-direction: column;

    & div:first-child,
    div:last-child {
      width: 100%;
    }
  }

  .primary-text {
    color: ${({theme}) => theme.palette.primary.main};
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    float: right;

    button {
      margin: 10px 0;
    }

    .focus-arrow-up {
      margin-top: 15px;
    }
    .focus-arrow-down {
      margin-bottom: 15px;
    }

    ${({theme}) => theme.breakpoints.down('md')} {
      width: 100%;
      padding: 50px 0 0 0;
      width: 100%;
    }

    & svg {
      ${({theme}) => theme.breakpoints.down('md')} {
        display: none;
      }
    }
  }
`;

export default GetGoingWrapper;
