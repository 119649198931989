import {createBrowserRouter} from 'react-router-dom';
import MainLayout from '@components/MainLayout/MainLayout';
import {ROUTER_PATHS} from '@constants';
import DocsPage from '@pages/DocsPage/DocsPage';
import HomePage from '@pages/HomePage/HomePage';
import InstallPowertoolsForAWS from '@pages/InstallPowertoolsForAWS/InstallPowertoolsForAWS';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
    ],
  },
  {
    path: ROUTER_PATHS.INSTALL_POWERTOOL_FOR_AWS,
    element: <MainLayout showFooter={false} />,
    children: [
      {
        path: ROUTER_PATHS.INSTALL_POWERTOOL_FOR_AWS,
        element: <InstallPowertoolsForAWS />,
      },
    ],
  },
  {
    path: '/docs',
    element: <MainLayout />,
    children: [
      {
        path: '/docs',
        element: <DocsPage />,
      },
    ],
  },
]);

export default router;
