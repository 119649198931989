import {
  Box,
  Link as MuiLink,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {Link} from 'react-router-dom';
import {ReactComponent as ArrowLeft} from '@svgs/arrow-left.svg';
import {ReactComponent as ArrowRight} from '@svgs/arrow-right.svg';
import Button from '@components/Button/Button';
import {appURLs, containerIds} from '@constants';
import {InstallWrapper} from './styles';

const Install = () => {
  const {palette, breakpoints} = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <InstallWrapper mt={isMobile ? 6 : 15}>
      <Box className="button-container">
        {!isMobile && <ArrowRight stroke={palette.info.main} />}
        <MuiLink
          to={
            isMobile
              ? appURLs.installPowertoolsURL
              : containerIds.installPowertoolsForAws
          }
          component={Link}
          width={'inherit'}>
          <Button color={'primary'} size="large" className={'install-btn'}>
            Install Powertools for AWS
          </Button>
        </MuiLink>
        {!isMobile && <ArrowLeft stroke={palette.info.main} />}
      </Box>
      <Box marginTop={5} display={'flex'} justifyContent={'center'}>
        <Typography className={'description'}>
          Powertools for AWS is a developer toolkit to implement Serverless best
          practices and increase developer velocity
        </Typography>
      </Box>
    </InstallWrapper>
  );
};

export default Install;
