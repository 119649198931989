import MuiTypography from '@mui/material/Typography';
import TypographyProps from './Typography.props';

const Typography = (props: TypographyProps) => {
  const {children, bold} = props;

  return (
    <MuiTypography fontWeight={bold ? 'bold' : 'normal'} {...props}>
      {children}
    </MuiTypography>
  );
};

export default Typography;
