import React, {useEffect, useRef, useState} from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {ReactComponent as ThunderMobileIcon} from '@images/thunder-mobile.svg';
import {ReactComponent as ThunderIcon} from '@images/thunder.svg';
import {code, optimizedCode, strikeThroughLineNumbers} from '../constants';
import {CodeBlockWrapper} from '../styles';
import {ICodeBlockProps} from './CodeBlock.types';
import nightOwl from './theme/night-owl';

const CodeBlock = ({checked, theme, isMobile = false}: ICodeBlockProps) => {
  const [codeToDisplay, setCodeToDisplay] = useState(code);
  const [linesToStrike, setLinesToStrike] = useState<number[]>([]);
  const ref = useRef<any>(null);
  const [linesCount, setLinesCount] = useState(0);

  useEffect(() => {
    if (checked) {
      setTimeout(() => {
        setLinesToStrike(strikeThroughLineNumbers);
        setTimeout(() => {
          setLinesToStrike([]);
          setCodeToDisplay(optimizedCode);
        }, 1000);
      }, 200);
    } else {
      setCodeToDisplay(code);
    }
  }, [checked]);

  useEffect(() => {
    // updates total lines count on code change
    setLinesCount(ref.current?.childNodes[1].childNodes[0].childNodes.length);
  }, [ref.current, codeToDisplay]);

  useEffect(() => {
    // appends number count to each line of code
    if (ref.current) {
      const numberLines = ref.current.childNodes[1].childNodes[0];
      for (let x = 0; x < (linesCount || 1) - 1; x++) {
        const spanElement = document.createElement('span');
        spanElement.className = 'line-number';
        spanElement.innerHTML = (x + 1).toString();
        spanElement.style.paddingRight = '20px';
        spanElement.style.color = '#ffffff4d';
        if (!numberLines.childNodes[x]) {
          return;
        }
        const hasClass =
          numberLines.childNodes[x].childNodes[0].classList?.contains(
            'line-number',
          );
        if (hasClass) {
          numberLines.childNodes[x].childNodes[0].remove();
        }
        numberLines.childNodes[x].prepend(spanElement);
      }
    }
  }, [ref.current, linesCount]);

  useEffect(() => {
    // applies/removes strike through line decoration
    const numberLines = ref.current.childNodes[1].childNodes[0];
    for (let x = 0; x < numberLines.childNodes.length; x++) {
      if (linesToStrike.length) {
        if (linesToStrike.includes(x + 1)) {
          numberLines.childNodes[x].style.textDecorationLine = 'line-through';
          numberLines.childNodes[x].style.textDecorationColor =
            theme.palette.primary.main;
        }
      } else {
        numberLines.childNodes[x].style.textDecorationLine = 'none';
      }
    }
  }, [linesToStrike]);

  return (
    <CodeBlockWrapper checked={checked} ref={ref}>
      {isMobile ? <ThunderMobileIcon /> : <ThunderIcon />}
      <SyntaxHighlighter
        language="python"
        style={nightOwl as Record<string, React.CSSProperties>}
        wrapLines={true}
        showLineNumbers={false}
        showInlineLineNumbers={false}>
        {codeToDisplay}
      </SyntaxHighlighter>
    </CodeBlockWrapper>
  );
};

export default CodeBlock;
