import {useRef, useState} from 'react';
import {ArrowBack, ArrowForward} from '@mui/icons-material';
import {Box, Divider, useMediaQuery, useTheme} from '@mui/material';
import {ReactComponent as QuoteIcon} from '@svgs/quote.svg';
import Button from '@components/Button/Button';
import Typography from '@components/Typography/Typography';
import {CARDS, THEME} from '@constants';
import {
  MobSuccessCard,
  SuccessCard,
  SuccessSlider,
  VoicesOfSuccessWrapper,
} from './styles';

const VoicesOfSuccess = () => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const successCardRef = useRef<HTMLDivElement>(null);

  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkMode = theme.palette.mode === THEME.dark;

  const handleNext = () => {
    if (sliderRef.current) {
      const successCardWidth: number =
        successCardRef.current?.clientWidth ?? 780;

      sliderRef.current.scrollLeft += successCardWidth;

      setActiveCardIndex((prevIndex) =>
        prevIndex < CARDS.length - 1 ? prevIndex + 1 : prevIndex,
      );
    }
  };
  const handleBack = () => {
    if (sliderRef.current) {
      const successCardWidth: number =
        successCardRef.current?.clientWidth ?? 780;

      sliderRef.current.scrollLeft -= successCardWidth;

      setActiveCardIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex,
      );
    }
  };
  return (
    <VoicesOfSuccessWrapper my={12}>
      <Box my={2}>
        <QuoteIcon />
      </Box>
      <Typography className={'title'} color={'text.primary'} variant="h3" bold>
        Voices of Success
      </Typography>
      <Typography className={'subTitle'} variant="body1" mt={1}>
        Hear what our customers have to say
      </Typography>
      <SuccessSlider ref={sliderRef} mt={isMobile ? 5 : 7}>
        <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'}>
          {CARDS.map(
            (
              {
                id,
                personImage,
                personImageSmall,
                personName,
                personTitle,
                feedback,
                Logo,
                lightModeColor,
                darkModeColor,
              },
              index,
            ) => {
              const isActive = activeCardIndex === index;
              const isFirst = index === 0;
              const isLast = index === CARDS.length - 1;
              const prevDisabled = !isActive || isFirst;
              const nextDisabled = !isActive || isLast;

              return isMobile ? (
                <MobSuccessCard
                  key={id}
                  className={`${isFirst ? 'first-card' : ''}`}>
                  <Logo
                    fill={isDarkMode ? darkModeColor : lightModeColor}
                    height={60}
                  />
                  <Typography className={'feedback'}>{feedback}</Typography>
                  <img
                    src={personImageSmall}
                    alt="person"
                    className={'person-image'}
                  />
                  <Typography className={'person-name'}>
                    {personName}
                  </Typography>
                  <Typography className={'person-title'}>
                    {personTitle}
                  </Typography>
                </MobSuccessCard>
              ) : (
                <SuccessCard
                  key={id}
                  ref={successCardRef}
                  className={`${isActive ? 'active' : ''}`}>
                  <Box p={4} display={'flex'}>
                    <Box className={'leftBlock'}>
                      <img src={personImage} height={340} alt="person" />
                    </Box>
                    <Box className={'rightBlock'}>
                      <Box minHeight={150}>
                        <Typography className={'feedback'}>
                          {feedback}
                        </Typography>
                      </Box>
                      <Box my={2}>
                        <Divider />
                      </Box>
                      <Logo
                        fill={isDarkMode ? darkModeColor : lightModeColor}
                        height={60}
                      />
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <Box display={'flex'} flexDirection={'column'}>
                          <Typography className={'person-name'}>
                            {personName}
                          </Typography>
                          <Typography className={'person-title'}>
                            {personTitle}
                          </Typography>
                        </Box>
                        <Box>
                          <Button
                            color={prevDisabled ? 'info' : 'primary'}
                            size={'small'}
                            onClick={handleBack}
                            style={{marginRight: '5px'}}
                            className={'arrow-back-btn'}>
                            <ArrowBack color={'inherit'} />
                          </Button>
                          <Button
                            color={nextDisabled ? 'info' : 'primary'}
                            size={'small'}
                            onClick={handleNext}
                            className={'arrow-forward-btn'}>
                            <ArrowForward color={'inherit'} />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </SuccessCard>
              );
            },
          )}
        </Box>
      </SuccessSlider>
    </VoicesOfSuccessWrapper>
  );
};

export default VoicesOfSuccess;
