import InstallLanguage from '@components/InstallLanguage/InstallLanguage';
import {InstallPowertoolsForAWSContainer} from './styles';

const InstallPowertoolsForAWS = () => {
  return (
    <InstallPowertoolsForAWSContainer>
      <InstallLanguage />
    </InstallPowertoolsForAWSContainer>
  );
};

export default InstallPowertoolsForAWS;
