import React, {createContext} from 'react';
import {LOCAL_STORAGE_KEYS, THEME} from '@constants';

const themeMode = localStorage.getItem(LOCAL_STORAGE_KEYS.themeMode);

export interface IContextVal {
  theme: THEME;
}
export const ContextValue: IContextVal = {
  theme: themeMode === THEME.dark ? THEME.dark : THEME.light,
};

export interface IContextObj {
  globalContextValue: IContextVal;
  setGlobalContextValue: React.Dispatch<React.SetStateAction<IContextVal>>;
}

export const CONTEXT_INIT_VALUE: IContextObj = {
  globalContextValue: ContextValue,
  setGlobalContextValue: () => {
    return;
  },
};

export const GlobalContext = createContext(CONTEXT_INIT_VALUE);
