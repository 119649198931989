import {Box} from '@mui/material';
import styled from 'styled-components';

export const VoicesOfSuccessWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    font-weight: 800;
    text-align: center;
    ${({theme}) => theme.breakpoints.down('md')} {
      font-size: 40px;
    }
  }
  .subTitle {
    font-weight: 400;
    color: ${({theme}) => theme.palette.text.secondary};
    ${({theme}) => theme.breakpoints.down('md')} {
      font-size: 18px;
    }
  }
`;

export const SuccessSlider = styled(Box)`
  display: flex;
  flex-flow: row;
  width: 100%;
  padding: 10px;
  overflow: hidden;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  ${({theme}) => theme.breakpoints.down('md')} {
    flex-flow: column;
  }
`;

export const SuccessCard = styled(Box)`
  border-radius: 12px;
  margin: 10px 25px;
  transition: transform 0.5s;
  position: relative;
  opacity: 0.2;

  &.active {
    transition: all 0.5s;
    background: ${({theme}) => theme.palette.background.paper};
    transform: scale(1.1);
    box-shadow: 0px 4px 10px 0px #0000000d;
    opacity: 1;

    img {
      border-radius: 10px;
    }
  }

  .leftBlock {
    width: 180px;
  }
  .rightBlock {
    width: 530px;
    display: flex;
    flex-direction: column;
  }

  .feedback {
    font-size: 16px;
    font-weight: 400;
  }
  .person-name {
    font-size: 32px;
    font-weight: 700;
  }
  .person-title {
    font-size: 12px;
    font-weight: 400;
    color: ${({theme}) => theme.palette.text.secondary};
  }
  .arrow-back-btn,
  .arrow-forward-btn {
    box-shadow: none;
    min-width: 55px;
    height: 55px;
    padding: 0;
  }
`;

export const MobSuccessCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  margin: 20px 0;
  padding: 20px;

  .feedback {
    margin: 20px 0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }

  .person-image {
    width: 100px;
    margin: 10px 0;
  }

  .person-name {
    font-size: 24px;
    font-weight: 700;
  }
  .person-title {
    font-size: 12px;
    font-weight: 400;
  }

  &.first-card {
    box-shadow: 0px 4px 10px 0px #0000000d;
    background: ${({theme}) => theme.palette.background.paper};
  }
`;
