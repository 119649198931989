import {Box, Switch} from '@mui/material';
import styled from 'styled-components';
import thunderGrey from '@images/thunder-grey.png';
import thunder from '@images/thunder.png';
import {THEME} from '@constants';

const borderRadius = 20;

export const ThunderSwitch = styled(Switch)(({theme}) => ({
  width: '140px !important',
  height: '120px !important',
  backgroundColor: theme.palette.info.main,
  borderRadius,

  '& .MuiSwitch-switchBase': {
    margin: 5,
    padding: 0,
    height: 110,
    width: '50%',
    transform: 'translateX(0px)',
    transitionDuration: '350ms',
    '&.Mui-checked': {
      transform: 'translateX(60px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#232F3F',

        '&:before': {
          backgroundImage: `url(${thunder})`,
          padding: 10,
          backgroundSize: '80px 80px',
        },
      },

      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.info.main,
      },
    },
  },

  '& .MuiSwitch-thumb': {
    backgroundColor: 'white',
    borderRadius,
    boxShadow: 'none',
    width: '100%',
    height: '100%',

    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(${thunderGrey})`,
      backgroundSize: '45px 45px',
    },
  },

  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.info.main,
    borderRadius,
  },
}));

export const StatsWrapper = styled(Box)<{checked: boolean}>`
  display: flex;
  flex-direction: column;

  .lines-of-code {
    p:last-child {
      color: ${({theme}) => theme.palette.text.primary};
      font-size: 20px;
    }

    .strike-through {
      position: relative;
      font-family: var(--AmazonEmber);
      font-weight: 400;
      font-size: 56px;
      color: ${({theme}) => theme.palette.info.contrastText};

      &::after {
        position: absolute;
        content: '';
        width: 120%;
        height: 3px;
        background: ${({theme, checked}) =>
          checked ? theme.palette.text.primary : 'none'};
        left: -10px;
        bottom: 30px;
        transform: rotate(155deg);
        @keyframes strikeThrough {
          0% {
            width: 0;
            bottom: 0;
          }
          100% {
            width: 120%;
            bottom: 30px;
          }
        }
        animation-name: ${({checked}) => (checked ? 'strikeThrough' : '')};
        animation-duration: 0.5s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }
    #lines-counter {
      margin-left: 10px;
      color: ${(props) =>
        props.checked
          ? props.theme.palette.primary.main
          : props.theme.palette.info.contrastText};
      font-weight: 700;
      font-size: 56px;
      font-family: var(--AmazonEmber);
      display: ${({checked}) => (checked ? 'inline-block' : 'none')};
    }
  }

  .reduced {
    margin-top: 20px;
    #percentage-counter {
      color: ${({theme, checked}) =>
        checked ? theme.palette.primary.main : theme.palette.info.contrastText};
      font-weight: ${({checked}) => (checked ? 700 : 400)};
      font-size: 56px;
      font-family: var(--AmazonEmber);
    }
    P:last-child {
      color: ${({theme}) => theme.palette.text.primary};
      font-size: 20px;
    }
    ${({theme}) => theme.breakpoints.down('md')} {
      margin-top: unset;
    }
  }
  ${({theme}) => theme.breakpoints.down('md')} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const SwitchWrapper = styled(Box)`
  h3 {
    font-weight: 700;
    span {
      font-family: var(--PTMono);
      font-weight: 400;
      font-size: 48px;
    }
    ${({theme}) => theme.breakpoints.down('md')} {
      font-size: 40px;
      span {
        font-size: 40px;
      }
    }
  }
`;

export const CodeBlockWrapper = styled(Box)<{checked: boolean}>`
  position: relative;
  background-color: ${({theme}) =>
    theme.palette.mode === THEME.dark ? '#f0f0f00d' : '#232f3f'};
  border-radius: 10px;
  flex-basis: 60%;
  pre {
    background-color: transparent;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    box-shadow: 0px -10px 30px 0px #0000001a;
    margin: 0;
    padding: 35px !important;
    border-radius: 10px;
    height: 1165px;
    overflow-y: scroll;
    position: relative;
    z-index: 1;
    &::-webkit-scrollbar {
      display: none;
    }
    @keyframes thunderFade {
      0% {
        backdrop-filter: blur(50px);
      }
      25% {
        backdrop-filter: blur(25px);
      }
      50% {
        backdrop-filter: blur(10px);
      }
      75% {
        backdrop-filter: blur(25px);
      }
      100% {
        backdrop-filter: blur(50px);
      }
    }
    animation: ${({checked}) => (checked ? '2s linear 0.2s thunderFade' : '')};

    .linenumber {
      margin-right: 20px;
    }
    code {
      white-space: pre-wrap !important;
    }

    span {
      min-width: 3.25rem !important;
      overflow-wrap: break-word;
      @keyframes faded {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          opacity: 1;
        }
      }
      animation: ${({checked}) => `${checked ? '1s linear 1s faded' : ''}`};
    }

    ${({theme}) => theme.breakpoints.down('md')} {
      height: 1250px;
      border-radius: 0;
    }
    ${({theme}) => theme.breakpoints.down('sm')} {
      padding: 20px !important;
      overflow-x: hidden !important;
      width: inherit;

      .linenumber {
        margin-right: 0;
      }
    }
  }

  svg {
    position: absolute;
    top: 5%;
    left: -7.5%;
    z-index: 0;
    height: 100%;
    width: 115%;
    defs {
      linearGradient {
        .stop-color {
          stop-color: ${({theme, checked}) =>
            checked
              ? theme.palette.primary.main
              : theme.palette.mode === THEME.dark
              ? '#91979F33 !important'
              : '#d9d9d9 !important'};
        }
      }
    }
    ${({theme}) => theme.breakpoints.down('sm')} {
      top: 0;
    }
  }
  ${({theme}) => theme.breakpoints.down('md')} {
    flex-basis: 100%;
  }

  ${({theme}) => theme.breakpoints.down('sm')} {
    width: calc(100% + 24px);
    left: -24px;
    margin-top: 30px;
  }
`;
