import {RefObject, useEffect, useMemo, useState} from 'react';

export const useInViewPort = (ref: RefObject<HTMLElement>) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting),
        {threshold: 0.5},
      ),
    [ref],
  );

  useEffect(() => {
    if (ref) {
      observer.observe(ref.current as HTMLElement);
    }
    return () => observer.disconnect();
  }, []);

  return isIntersecting;
};
