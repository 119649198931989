import {Container} from '@mui/material';
import styled, {WebTarget, css} from 'styled-components';
import {ReactComponent as DiscordSVG} from '@images/discord.svg';
import {ReactComponent as GithubSVG} from '@images/github.svg';
import {ReactComponent as MoonSVG} from '@images/moon.svg';
import {ReactComponent as SunSVG} from '@images/sun.svg';

export const FooterWrapper = styled(Container)`
  width: 100%;
  margin-top: auto;

  ${({theme}) => theme.breakpoints.down('md')} {
    display: flex !important;
    flex: 1;
  }
  .power-tools {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .footer-links-wrapper {
    display: flex;
    align-items: center;
    ${({theme}) => theme.breakpoints.down('md')} {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .link {
    font-size: 14px;
    font-weight: 400;
    margin-right: 20px;
    color: ${({theme}) => theme.palette.text.secondary};
    text-decoration: none;
  }
  .divider {
    margin: 20px 0;
  }

  .copy-rights-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    ${({theme}) => theme.breakpoints.down('sm')} {
      justify-content: center;
    }
    .copy-rights {
      font-size: 14px;
      font-weight: 400;
      color: ${({theme}) => theme.palette.text.secondary};
      text-align: center;
      ${({theme}) => theme.breakpoints.down('md')} {
        text-align: start;
        margin-bottom: 10px;
      }
      ${({theme}) => theme.breakpoints.down('sm')} {
        text-align: center;
        width: 100%;
      }
    }
    .makeen-logo-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 15px;
      img {
        ${({theme}) => theme.breakpoints.down('sm')} {
          margin-top: 20px;
        }
      }
    }
  }
`;

const commonSVGStyle = css`
  width: 30px;
  height: 30px;
  cursor: pointer;
  path {
    fill: ${({theme}) => theme.palette.info.dark};
  }
`;
export const StyledGitHub = styled(GithubSVG)`
  ${commonSVGStyle};
`;
export const StyledDiscord = styled(DiscordSVG)`
  ${commonSVGStyle};
  margin-right: 40px;
  margin-left: 22px;
`;

export const StyledMoon = styled<WebTarget>(MoonSVG)`
  ${commonSVGStyle};
  border-radius: 5px;
  transition: background 0.7s;
  path {
    fill: ${({theme}) => theme.palette.info.dark};
  }
  ${({active}) =>
    active &&
    css`
      background: ${({theme}) => theme.palette.text.secondary};
      path {
        fill: ${({theme}) => theme.palette.background.default};
      }
    `}
`;

export const StyledSun = styled<WebTarget>(SunSVG)`
  ${commonSVGStyle};
  border-radius: 5px;
  transition: background 0.7s;
  path {
    fill: ${({theme}) => theme.palette.info.secondary};
  }
  ${({active}) =>
    active &&
    css`
      background: ${({theme}) => theme.palette.info.dark};
      path {
        fill: ${({theme}) => theme.palette.background.default};
      }
    `}
`;
