import Box from '@mui/material/Box';
import styled from 'styled-components';

export const ThrivingCommunityWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    & h3 {
      width: 70%;
      font-weight: 800;
      ${({theme}) => theme.breakpoints.down('md')} {
        width: 100%;
        font-size: 40px;
      }
    }
    & p {
      text-align: center;
    }
  }
  .join-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({theme}) => theme.breakpoints.down('sm')} {
      width: 100%;
    }

    img:first-child,
    img:last-child {
      width: 30%;
      ${({theme}) => theme.breakpoints.down('md')} {
        width: 20%;
      }
      ${({theme}) => theme.breakpoints.down('sm')} {
        display: none;
      }
    }

    svg {
      flex: 1;
    }

    .join-community-btn {
      margin: 0 10px;
      ${({theme}) => theme.breakpoints.down('sm')} {
        margin: 0 0;
        width: 100%;
      }
    }
  }
  .community-image {
    margin-top: 50px;
    width: 80%;

    ${({theme}) => theme.breakpoints.down('sm')} {
      display: none;
    }
  }
  .conversation-image {
    margin-top: 50px;
    ${({theme}) => theme.breakpoints.up('sm')} {
      display: none;
    }
  }
`;
