import {useMemo, useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {RouterProvider} from 'react-router-dom';
import InitTheme from '@themes/InitTheme';
import {
  ContextValue,
  GlobalContext,
  IContextObj,
  IContextVal,
} from './context/global';
import router from './router';

function App() {
  const [globalContextValue, setGlobalContextValue] =
    useState<IContextVal>(ContextValue);

  const context_value: IContextObj = useMemo(
    () => ({globalContextValue, setGlobalContextValue}),
    [globalContextValue, setGlobalContextValue],
  );

  return (
    <GlobalContext.Provider value={context_value}>
      <InitTheme>
        <CssBaseline />
        <RouterProvider router={router} />
      </InitTheme>
    </GlobalContext.Provider>
  );
}

export default App;
