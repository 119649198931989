import {Container} from '@mui/material';
import styled from 'styled-components';

export const HomePageContainer = styled(Container)`
  margin: 100px 0;
  min-height: 100vh;

  ${({theme}) => theme.breakpoints.down('sm')} {
    margin-top: 75px;
  }
`;
