import {StyledMoon, StyledSun} from '@components/Footer/styles';
import {THEME} from '@constants';
import {SwitchWrapper} from './styles';

const ThemeBtnSwitch = ({
  modeSwitch,
  setModeSwitch,
}: {
  modeSwitch: string;
  setModeSwitch: (theme: THEME) => void;
}) => {
  return (
    <SwitchWrapper>
      <StyledSun
        active={modeSwitch === THEME.light}
        onClick={() => setModeSwitch(THEME.light)}
      />
      <StyledMoon
        active={modeSwitch === THEME.dark}
        onClick={() => setModeSwitch(THEME.dark)}
      />
    </SwitchWrapper>
  );
};

export default ThemeBtnSwitch;
