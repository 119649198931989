import {useState} from 'react';
import {Outlet} from 'react-router-dom';
import Footer from '@components/Footer/Footer';
import Navbar from '@components/Navbar/Navbar';
import {MainLayoutContainer} from './styles';

interface MainLayoutProps {
  showFooter?: boolean;
}

const MainLayout = ({showFooter = true}: MainLayoutProps) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleDrawerToggle = (state?: boolean) => {
    setIsMobileMenuOpen((prevState) => state ?? !prevState);
  };

  return (
    <MainLayoutContainer id="main-layout-container">
      <Navbar
        onDrawerToggle={handleDrawerToggle}
        isMobileMenuOpen={isMobileMenuOpen}
      />
      {!isMobileMenuOpen ? <Outlet /> : null}
      {(showFooter || isMobileMenuOpen) && (
        <Footer mobileMenuOpened={isMobileMenuOpen} />
      )}
    </MainLayoutContainer>
  );
};

export default MainLayout;
