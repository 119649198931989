import {useContext, useEffect} from 'react';
import {Box, Container, Toolbar} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import Button from '@components/Button/Button';
import {LOCAL_STORAGE_KEYS, THEME, appURLs} from '@constants';
import {GlobalContext} from '../../context/global';
import {MobileMenuTypes} from './MobileMenu.props';

const MobileMenu = ({toggleMobileMenu}: MobileMenuTypes) => {
  const {setGlobalContextValue} = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    setGlobalContextValue((prevState) => ({
      ...prevState,
      theme: THEME.dark,
    }));

    return () => {
      const currentThemeMode = localStorage.getItem(
        LOCAL_STORAGE_KEYS.themeMode,
      ) as THEME;

      setGlobalContextValue((prevState) => ({
        ...prevState,
        theme: currentThemeMode ?? THEME.light,
      }));
    };
  }, []);

  return (
    <Container className="mobile-menu" component="main">
      <Toolbar />

      <Box mt={2}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            toggleMobileMenu();
            navigate(appURLs.documentationURL);
          }}>
          Documentation
        </Button>
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            toggleMobileMenu();
            navigate(appURLs.installPowertoolsURL);
          }}>
          Install Powertools for AWS
        </Button>
      </Box>
      <Box mt={2}>
        <Button variant="outlined" color="inherit" fullWidth>
          Join Community
        </Button>
      </Box>
    </Container>
  );
};

export default MobileMenu;
